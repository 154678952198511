/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import firebase from "firebase"

firebase.initializeApp({
  apiKey: "AIzaSyAa2wjmbLHAS8kEexmFT_tryBorrRa03D0",
  authDomain: "sfactor-website.firebaseapp.com",
  databaseURL: "https://sfactor-website.firebaseio.com",
  projectId: "sfactor-website",
  storageBucket: "sfactor-website.appspot.com",
  messagingSenderId: "128769519672",
  appId: "1:128769519672:web:5c97e9473df1ef768736cd",
  measurementId: "G-DF45W82X1Z",
})
firebase.analytics()
